@import '../variables/variables';

.mat-card {
    .mat-card-header-text {
        margin: 0;
    }

    .mat-card-avatar + .mat-card-header-text {
        margin-left: 10px;
    }
}

// Show form hint only when form field is focused
.mat-form-field:not(.mat-focused) .mat-hint {
    display: none;
}
